import Container from '../components/Container'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import NewArticleList from '../components/NewArticleList'
import RelatedArticleList from '../components/RelatedArticleList'
import PageBody from '../components/PageBody'
import PostDetails from '../components/PostDetails'
import React from 'react'
import SEO from '../components/SEO'
import ShareButtonList from '../components/ShareButtonList'
import TagList from '../components/TagList'
import { graphql } from 'gatsby'

const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    slug,
    metaDescription,
    heroImage,
    body,
    tags,
    publishDateISO,
    updatedAtISO,
    keywords
  } = data.contentfulPost

  const { basePath, siteUrl } = pageContext

  let ogImage
  try {
    ogImage = heroImage.fluid.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
        slug={slug}
        publishDateISO={publishDateISO}
        updatedAtISO={updatedAtISO}
      />
      <Hero title={title} image={heroImage} height={'50vh'} />
      <Container>
        <div>
          {tags && <TagList tags={tags} basePath={basePath} />}
          <ShareButtonList title={title} url={`${siteUrl}/${slug}`} />
          <PostDetails
            date={updatedAtISO}
          />
          <PageBody body={body} keywords={keywords} />
          <NewArticleList />
          <RelatedArticleList tags={tags} />
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        gatsbyImageData(layout: FIXED)
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          htmlAst
          excerpt(pruneLength: 320)
        }
      }
      updatedAtISO: updatedAt(formatString: "YYYY-MM-DD")
      keywords
    }
  }
`

export default PostTemplate

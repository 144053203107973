import { graphql, useStaticQuery } from 'gatsby'

import Card from './Card'
import CardList from './CardList'
import React from 'react'
import styled from '@emotion/styled'

const SectionTitle = styled.h2`
  margin: 0;
  font-size: 17px;
  font-weight: 700;
  background: #f89174;
  color: #fff;
  padding: 7px 15px;
  margin-bottom: 1em;
`

const RelatedArticleList = ({ tags }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPost(
        sort: {fields: [updatedAt], order: DESC}
        limit: 100
      ) {
        edges {
          node {
            title
            slug
            heroImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              ogimg: resize(width: 1800) {
                src
              }
              gatsbyImageData(layout: FIXED)
            }
            body {
              childMarkdownRemark {
                html
                excerpt(pruneLength: 80)
              }
            }
            tags {
              slug
            }
          }
        }
      }
    }
  `)

  if (! tags) return ''

  const tags_pref = ['aichi', 'okayama', 'kanagawa', 'hokkaido', 'tochigi', 'fukui', 'kumamoto', 'mie', 'saitama', 'tokyo', 'niigata', 'toyama', 'fukushima', 'ibaraki', 'gunma', 'tottori', 'ishikawa', 'kochi', 'chiba', 'kyoto', 'osaka', 'gifu', 'shiga', 'hyogo', 'hiroshima', 'tokushima', 'shimane', 'ehime', 'saga', 'miyazaki', 'shizuoka', 'wakayama', 'nagano', 'nagasaki', 'kagawa', 'yamaguchi', 'oita', 'yamanashi', 'fukuoka', 'nara', 'kagoshima', 'yamagata', 'okinawa', 'miyagi', 'iwate', 'akita', 'aomori']
  const tags_target = tags.filter(e => ! tags_pref.includes(e.slug))
  if (tags_target.length < 1) return ''
  
  const posts = data.allContentfulPost.edges.filter(e => {
    if (! e.node.tags) return false

    return e.node.tags.map(e => e.slug).includes(tags_target[0]['slug'])
  }).filter((_e, i) => i < 12)

  if (posts.length < 1) return ''
  
  return (
    <div>
      <SectionTitle>関連記事</SectionTitle>
      <CardList>
        {posts.map(({ node: post }) => (
          <Card key={post.id} {...post} basePath="" />
        ))}
      </CardList>
    </div>
  )
}

export default RelatedArticleList

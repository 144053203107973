import { graphql, useStaticQuery } from 'gatsby'

import Card from '../components/Card'
import CardList from '../components/CardList'
import React from 'react'
import styled from '@emotion/styled'

const SectionTitle = styled.h2`
  margin: 0;
  font-size: 17px;
  font-weight: 700;
  background: #f89174;
  color: #fff;
  padding: 7px 15px;
  margin-bottom: 1em;
`

const NewArticleList = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 12
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            gatsbyImageData(layout: FIXED)
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }  `)

  const posts = data.allContentfulPost.edges
  return (
    <div>
      <SectionTitle>新着記事</SectionTitle>
      <CardList>
        {posts.map(({ node: post }) => (
          <Card key={post.id} {...post} basePath="" />
        ))}
      </CardList>
    </div>
  )
}

export default NewArticleList
